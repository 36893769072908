import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Cta from "../components/service/Cta";
import WorkCollaboration from "../components/about/WorkCollaboration";
import SwiperTabs from "../components/SwiperTabs";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import ContentCommon from "../components/hire/ContentCommon";
import Reports from "../components/hire/Reports";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Hire AngularJs Developers`,
    text: "Hire Top 1% Talent",
    content: "Hire AngularJS Developers from Octet to create unique, high-quality custom solutions.",
    list: [
      'Flexible Hiring Options',
      'Cross-functional Designer',
      'Time-Zone Friendly'
    ],
    linkText: 'Hire AngularJs Developers',
    link: '/contact-us/'
  };
  const commonContent = {
    title: 'Hire Dedicated AngularJs Developer for Evolving Needs',
    text: "Hire dedicated AngularJS developer who assists businesses in staying ahead of the digital curve and achieving long-term growth by offering scalable solutions that improve productivity and website functionality.",
    list: [
      {
        title: 'Improved Engagement',
        text: "Hire dedicated AngularJS developer to optimize your application's speed to increase engagement and produce a responsive and useful interface."
      },
      {
        title: 'Rich User Interfaces',
        text: 'Hire AngularJS Experts to create effective and visually appealing websites by leveraging a rich library and data-binding features.'
      },
      {
        title: 'Enhanced Performance',
        text: 'Hire Angular JS developers who improve website performance by utilizing efficient rendering, streamlined code, and modular architecture.'
      }
    ],
    image: ["hire-content-angular.webp"],
    titleText: 'AngularJS Developers',
    alt: 'Benefits of hiring Angular developers'
  }
  const commonContent2 = {
    title: 'Hire Dedicated Angular Developers from Octet',
    text: "Leading companies trust Octet Design Studio to hire AngularJS Developer to acquire top design expertise with industry-specific experience.",
    list: [
      {
        title: 'Hire Angular Developer In 48 Hours',
        text: 'Hire dedicated Angular Developers to ensure swift recruitment processes, bringing aboard and hiring the best mobile app designers within 48 hours.'
      },
      {
        title: 'Transparent Reporting',
        text: 'Stay in control with detailed timesheet reports from our AngularJS Experts to track progress and productivity.'
      },
      {
        title: '160 hours of Dedicated Work',
        text: 'Our developers commit 160 hours of focused, high-quality work, ensuring productivity and results.'
      },
      {
        title: 'Seamless Collaboration',
        text: 'Hire dedicated Angular Developers and enjoy seamless collaboration with a dedicated manager who oversees your design needs and ensures a smooth workflow.'
      }
    ],
    image: ["hire-content-2-angular.webp"],
    titleText: 'Hire Angular developer',
    alt: 'Hire ReactJS developers from a leading web development company'
  }
  const commonContent3 = {
    title: `3 Steps to Hire AngularJS Developer`,
    text: "Experience the Octet advantage by hiring AngularJS developer in three easy steps.",
    list: [
      {
        title: 'Step 1: Share Your Requirements',
        text: `Provide us with your project requirements, and we will share the resumes of our Angular developers for hire who best match your needs and business goals.`
      },
      {
        title: 'Step 2: Interview Designers',
        text: "Interview and evaluate our skilled AngularJS developers to select the most suitable candidate for your project's vision and goals."
      },
      {
        title: 'Step 3: Onboard And Begin',
        text: "Once you close and hire AngularJS developer, onboard them within 48 hours so they can dive into your project with their expertise and dedication."
      },
    ],
    image: ["hire-content-3-angular.webp"],
    titleText: 'Hire AngularJS developer',
    alt: 'Simple steps to hire AngularJS developer from Octet'
  }
  const cta = {
    title: `Expand your Project Leveraging Angular Developers`,
    contactText: 'Contact Us'
  };
  const content = [
    {
      title: "eCommerce Developers",
      content: "Our AngularJS developers use various tools and software to develop apps that meet users' needs and help convert visitors into customers. Hire dedicated AngularJS developer to create an enjoyable and seamless shopping experience for customers, which can increase sales and customer loyalty.",
    },
    {
      title: "Angular Developer for SaaS",
      content:
        "SaaS Angular developers specialize in SaaS platforms and create visually appealing and user-friendly designs for web and mobile applications. Hire dedicated Angular developers who will be creative problem solvers and develop beautiful and functional apps and websites that enhance the user experience.",
    },
    {
      title: "Enterprise Developer",
      content: "Hire Angular JS developers who will allow you to deliver scalable solutions for large-scale operations. These developers will address intricate workflows and compliance needs with precision and user-centric strategy. Our AngularJS Experts will work closely with developers and product managers to ensure the final mobile app design meets the enterprise's and its users' needs.",
    },
  ];
  const tabsData = {
    title: `Hire AngularJs Developers to Improve Product Efficiency`,
    des: `Hire AngularJS Developer to take your front-end development to the next level and create highly dynamic, future-ready apps.`,
    tabs: ["Single Page Applications ", "Enterprise Web Applications", "E-commerce Platforms", "Real-time Applications"],
    slides: [
      {
        title: 'Single Page Applications',
        desc1: "Hire Angular JS Developers to build dynamic and responsive SPAs that provide a seamless user experience. Our Angular experts build solutions by loading a single HTML page and dynamically updating it as the user interacts with the app.",
        // desc2: 'Hire UI designer for mobile app designing to obtain consistent user-centric design, better accessibility, and intuitive layouts.',
        list: [
          'Enhancing Apps Performance',
          'Providing Real-time Updates',
          'Creating Fast Loading Tiles'
        ]
      },
      {
        title: 'Enterprise Web Applications',
        desc1: "Hire AngularJS Developer to create scalable and robust enterprise-level applications that handle complex business logic and large volumes of data.",
        // desc2: 'Enhance your brand’s online presence with a focus on user-friendly, innovative design solutions tailored to your unique web UI UX needs.',
        list: [
          'Expertise in Modular Architecture',
          'Providing Easy Maintenance',
          'Delivering Cross-platform Accessibility'
        ]
      },
      {
        title: 'E-commerce Platforms',
        desc1: "Hire Angular JS Developers to create comprehensive e-commerce solutions that include features such as product catalogs, shopping carts, payment gateways, and order management systems.",
        // desc2: 'Our UI UX experts create visually appealing mobile designs that ensure conciseness and efficiency to deliver a better user experience.',
        list: [
          'Delivering Secure Transactions',
          'Expertise in User-friendly Interfaces',
          'Providing Seamless Integration'
        ]
      },
      {
        title: 'Real-time Applications',
        desc1: "Hire Angular Developer to build real-time applications such as chat applications, live streaming, and collaboration tools that require instant data updates and interactions.",
        // desc2: "Our UI UX designers will create workflows and optimize user experience for desktop devices to meet users' needs and expectations.",
        list: [
          'Providing Real-time Data',
          'Developing High-performance',
          'Boosting Real-time Engagement'
        ]
      }
    ],
    altOne: 'Single Page Applications',
    titleOne: 'Single Page Applications',
    altTwo: 'Enterprise Web Applications',
    titleTwo: 'Enterprise Web Applications',
    altThree: 'E-commerce Platforms',
    titleThree: 'E-commerce Platforms',
    altFour: 'Real-time Applications',
    titleFour: 'Real-time Applications',
    images: [
      'our-ui-ux.webp',
      'our-ui-ux-2.webp',
      'our-ui-ux-3.webp',
      'our-ui-ux-4.webp'
    ]
  };
  const projectData = [
    {
      title: "Landscape Pro",
      desc: "Simplifying Crew Management Process",
      Industry: "SaaS",
      firstAlt: "UI UX Design of a Schedule Screen to Manage Property",
      firstTitle: "Schedule Screen UI Design",
      secondAlt: "UI UX Design to Manage and Track User's Status",
      secondTitle: "UI Screen to Manage Users",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design Audit",
          link: "/design-audit/",
        },
      ],
      link: "/project/landscape-pro/",
      images: ["landscape-pro.webp", "landscape-pro-2.webp", "landscape-pro-3.webp"]
    },
    {
      title: "Survey2Connect",
      desc: "Streamlining Survey Collection Solution",
      Industry: "Enterprise",
      firstAlt: "UI UX Design To Create Survey",
      firstTitle: "Survey Creation Screen",
      secondAlt: "Ui Design To Create Questionnaire For Survey",
      secondTitle: "Questionnaire For Survey",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/survey2connect/",
      images: ["survey.webp", "survey-2.webp", "survey-3.webp"],
    },
    {
      title: "Elastic Sign",
      desc: "Efficient Design for Secure Document Signing",
      Industry: "SaaS",
      firstAlt: "UI Design to View all the Documents and their Status",
      firstTitle: "Manage Documents UI Design",
      secondAlt: "UI UX Design to Sign Documents Digitally",
      secondTitle: "UI Design to Sign Digital Documents",
      thirdAlt: "UI Screen to draw, upload or type your own signature",
      thirdTitle: "Create Your Own Signature",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "React Development",
          link: "/reactjs-development/",
        },
      ],
      link: "/project/elastic-sign",
      images: ["elastic-sign.webp", "elastic-sign-2.webp", "elastic-sign-3.webp"]
    },
    {
      title: "Unilogix App",
      desc: "Comprehensive Logistic Analytics Suite",
      Industry: "Logistics",
      firstAlt: "Mobile UI UX Screen Of Logistics Insight Application",
      firstTitle: "Logistics Services List",
      secondAlt: "Order And Warehouse Details",
      secondTitle: "Order Details",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/logistics-insight-app-design/",
      images: ["uni-logics.webp", "uni-logics-2.webp", "uni-logics-3.webp"],
    },
    {
      title: "Ship Delight",
      desc: "User-Centric Logistic Website Redesign",
      Industry: "Logistics",
      firstAlt: "UI UX Dashbaord To Check The Real Time Shipment Status",
      firstTitle: "Real Time Shipment Status",
      secondAlt: "UI Dashboard To Check The Performance Status",
      secondTitle: "NDR Status",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Wordpress Development",
          link: "/wordpress-development/",
        },
      ],
      link: "/project/shipdelight/",
      images: ["ship-delight-web.webp", "ship-delight-web-2.webp", "ship-delight-web-3.webp"],
    },
  ];
  const lastLength = projectData;
  const itemsList = [
    {
      title: '200+',
      text: 'Successful Projects '
    },
    {
      title: '30+',
      text: 'Experienced Designers'
    },
    {
      title: '80+',
      text: 'Satisfied Clients'
    }
  ];
  const reports = {
    title: `Hire Designers from <span class="h1-span">Octet vs Others</span>`,
    text: "Choosing Octet means efficient execution and a speedier ROI. Hire AngularJS Experts who are passionate and committed to developing captivating apps, websites, and responsive experiences.",
    listHead: [
      'Aspects',
      'Octet’s UI UX Designers',
      'Other Designers'
    ],
    list: [
      {
        title: 'Hiring Time',
        first: '2 Days',
        second: 'Long Lead Time'
      },
      {
        title: 'Hiring Model',
        first: 'Flexible Options',
        second: 'Limited Options'
      },
      {
        title: 'Timezone',
        first: 'Overlap',
        second: 'No Overlap'
      },
      { title: 'AngularJs Expertise', second:'Uncertain' },
      { title: 'Pre-screen Candidate' },
      { title: 'NDA Guarantee' },
      { title: '100% Replacement' },
      { title: 'Highend Systems' },
      { title: 'Free Trial'},
      { title: 'Hiring fees', first: 'true', second: 'Uncertain' },
      { title: 'Hidden Charges', first: 'true', second: 'Uncertain' },
    ]
  };
  const ourData = {
    title: 'Hear From Our Clients',
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['visa.webp'],
        stars: 4,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      }
    ]
  };
  const Questions = [
    "Why should you Hire Dedicated Angular Developers from Octet?",
    "How can you Hire AngularJS Developer from Octet?",
    "Which companies Hire Angular JS Developers?",
    "Does your Angular Developer come with a trial period?",
    "How does our transparent pricing system work?"
  ];
  const faqData = [
    {
      para: [
        `Octet boasts a skilled, creative, and functional team of AngularJS developers who specialize in crafting engaging and functional interfaces.`,
        `Our developers have a proven track record of delivering user-centric websites across diverse industries. They are well-versed in design principles and keep themselves updated with the latest design technologies and trends.`,
        `Hire dedicated Angular developers from Octet to ensure your product remains relevant and competitive.`
      ]
    },
    {
      para: [
        `Hiring AngularJS developer from Octet involves just 3 easy steps:`,
        `<strong><h4>Share Your Requirements:</h4></strong>`,
        `Tell us about your project needs, including the scope, objectives, target audience, and specific preferences or technical requirements. This will help us find the most suitable developers to meet your needs and expectations.`,
        `<strong><h4>Interview Designers:</h4></strong>`,
        `Once we understand your needs, we will share a list of developers who match your project needs.`,
        `You can then interview and evaluate our developers to assess their skills, experience, and creativity.`,
        `Choose the candidate who best matches your project goals.`,
        `<strong><h4>Onboard and Begin:</h4></strong>`,
        `After hiring Angular developer from us, onboard them onto your project team and provide them access to necessary resources.`,
      ]
    },
    {
      para: [
        `AngularJS Experts are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.`,
        `Whether designing mobile apps, building websites, software interfaces, or consumer electronics, our developers can enhance usability, functionality, and visual appeal for different companies.`,
      ]
    },
    {
      para: [
        `Hiring Angular Developers from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company.`,
      ]
    },
    {
      para: [
        `At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services.`,
        `Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring our developers, you can trust that you're getting the most value for your money.`,
      ]
    },
  ];
  const commonContent5 = {
    title: "Hire Angular Developer for Bespoke Services",
    para: 'Hire Angular Developer to obtain comprehensive solutions that are extensively tested to verify their functionality and scalability for long-term business growth.',
    content: [
      {
        title: "AngularJS Web Development",
        text: "Hire Angular Developer who uses Angularjs to design robust, interactive, and user-friendly online apps.",
      },
      {
          title: "AngularJS SPA Development",
          text: "Hire AngularJS Developer who will improve the page's site speed to increase overall interaction and web presence.",
        },
        {
          title: "Mobile Application Development",
          text: "Hire Angular JS Developers to keep your applications updated and optimized for optimum user engagement across all devices.",
        },
      {
        title: "AngularJS Consulting",
        text: "Hire AngularJS Developers who provide insights and strategic solutions for Angular projects to improve website performance.",
      },
      {
        title: "Custom Development",
        text: "Hire Angular Developer to obtain tailored Angular JS development services based on your company's requirements and project specifications.",
      },
      {
        title: "AngularJS Migration Services",
        text: "Hire Angular Developer for migration services to ensure a smooth website transition and increased security.",
      },
    ],
  };
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Why should you Hire Dedicated Angular Developers from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Octet boasts a skilled, creative, and functional team of AngularJS developers who specialize in crafting engaging and functional interfaces. 
          
          Our developers have a proven track record of delivering user-centric websites across diverse industries. They are well-versed in design principles and keep themselves updated with the latest design technologies and trends. 
          
          Hire dedicated Angular developers from Octet to ensure your product remains relevant and competitive."
              }
            },{
              "@type": "Question",
              "name": "How can you Hire AngularJS Developer from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring AngularJS developer from Octet involves just 3 easy steps:
          
          Share Your Requirements:
          Tell us about your project needs, including the scope, objectives, target audience, and specific preferences or technical requirements. This will help us find the most suitable developers to meet your needs and expectations.
          
          Interview Designers:
          Once we understand your needs, we will share a list of developers who match your project needs.
          You can then interview and evaluate our developers to assess their skills, experience, and creativity.
          Choose the candidate who best matches your project goals.
          
          Onboard and Begin:
          After hiring Angular developer from us, onboard them onto your project team and provide them access to necessary resources."
              }
            },{
              "@type": "Question",
              "name": "Which companies Hire Angular JS Developers?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "AngularJS Experts are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.
          
          Whether designing mobile apps, building websites, software interfaces, or consumer electronics, our developers can enhance usability, functionality, and visual appeal for different companies."
              }
            },{
              "@type": "Question",
              "name": "Does your Angular Developer come with a trial period?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring Angular Developers from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company."
              }
            },{
              "@type": "Question",
              "name": "How does our transparent pricing system work?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services. 
          
          
          Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring our developers, you can trust that you're getting the most value for your money."
              }
            }]
          }                                 
        `}
      </script>
    </Helmet>
      <Layout hire="Hire AngularJs Developers">
        <div>
          <HireBanner data={banner} pageName="Hire UI/UX Designer" />
          <section className="py-[40px]">
            <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="lg:h-[100px] md:h-[80px] h-[60px]">
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <SwiperTabs data={tabsData} normal light />
          <CommonServiceComponent data={commonContent5} mode={"light"} hire />
          <ContentCommon dark data={commonContent} />
          <Reports data={reports} />
          <ContentCommon data={commonContent2} dark />
          <WorkCollaboration grey content={content} title={`Hire AngularJS Experts <span class="h1-span">for Industry Specific</span>`} text={``} />
          <ContentCommon data={commonContent3} big reverse />
          <Cta lgtextClass="lg:text-[50px]" data={cta} />
          <Faq section Questions={Questions} faqData={faqData} des={'Explore common queries about our Angular developers, hiring AngularJS developers, pricing, and processes to gain clarity and confidence in your decision-making journey.'} />
        </div>
      </Layout>
    </>
  );
};

export default HirePage;
export const Head = () => (
  <Seo
    title="Hire AngularJs Developers | Hire AngularJS Developer"
    description="Hire AngularJS Developers to create Feature-Rich, Next-Gen Web Applications. Hire AngularJS Developer and move forward in Angular Development."
  />
);